.modal-watch .content {
    position: fixed;
    width: 100%; height: 100%;
    left: 0; top: 0;
    background-color: rgba(245, 242, 239, .93);
    color: $theme-black;
    overflow: hidden;
    opacity: 0;
}
.w-thumb {
    margin-top: 14vh;
    img {
        border-radius: 10px;
        box-shadow: 0 0 20px $theme-gray;
        max-width: 70vw;
    }
}
.w-title {
    margin-top: 5vh;
    font-size: 2.4rem;
}
.w-intro {
    margin-top: 4vh;
    text-align: left;
    font-size: 1rem;
    padding: 0 25vw;
    opacity: .9;
}
.w-see {
    margin-top: 7vh;
    a {
        display: block;
        width: 12rem; height: 3.7rem;
        font-size: 1.4rem;
        line-height: 3.7rem;
        color: $theme-black;
        border-radius: 6px;
        border: $theme-black 2px solid;
        margin: 0 auto;
        text-decoration: none;
        transition: all .3s ease;
        &:hover {
            color: #fff;
            background-color: $theme-black;
            border-radius: 12px;
        }
        &:active {
            color: $theme-black;
        }
    }
}
.w-close a {
    position: absolute; display: block;
    right: -3vmin; top: -3vmin;
    width: 17vmin; height: 17vmin;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px $theme-gray;
    transition: all .3s ease;
    &:hover {
        box-shadow: 0 0 25px $theme-gray;
    }
    div {
        position: absolute;
        width: 45%; height: 1px;
        top: calc(50% - .5px); left: calc(27.5%);
        &:nth-child(1) {
            transform: rotate(45deg);
            &::before, &::after {
                content: ""; position: absolute;
                height: 100%; width: 45%;
                background-color: $theme-black;
            }
            &::before { top: 0; left: 0;  }
            &::after  { top: 0; right: 0; }
        }
        &:nth-child(2) {
            transform: rotate(-45deg);
            background-color: $theme-black;
        }
    }
}

/*
 * Animations
 */
.modal-watch .content {
    animation: fadein .3s ease forwards;
}