/*
 * Theme colors
 */
$theme-black:        #666666;
$theme-green:        #98AD4E;
$theme-green-light:  #F3FBD2;
$theme-brown:        #695643;
$theme-white:        #EFEBE4;
$theme-gray:         #CCCCCC;

/*
 * Font
 */
@font-face {
    font-family: "Arial-Rounded-Bold";
    src: url("/stylesheets/fonts/Arial-Rounded-Bold.ttf");
}