* {
    margin: 0;
    padding: 0;
}
html {
    font-size: 16px;
}
body {
    background-color: $theme-white;
    font-family: "Arial-Rounded-Bold", "Open Sans", sans-serif, Roboto, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#root {
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    text-align: center;
}