/*
 * Keyframes - base
 */
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}
@keyframes scale0-1 {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}