@media screen and (max-width: 760px) {
    .preload {
        .icon {
            width: 16vmin; height: 16vmin;
            left: calc(50% - 8vmin); top: calc(50% - 8vmin);
        }
    }
    .logo {
        width: 70vmin;
        background-size: 70vmin;
        margin-top: 5vmin;
    }
    .description {
        margin-top: 5vmin;
        font-size: 16px;
    }
    .article {
        width: 80vmin;
        margin-top: 10vmin;
        a {
            width: 100%; height: 57vmin;
            margin-left: 0;
            margin-bottom: 8vmin;
            padding: 15px;
        }
        h4 {
            font-size: 15px;
            margin-top: 5px;
        }
    }
    .footer {
        margin-top: 7vmin;
        padding-bottom: 4vmin;
        a { margin-left: 3vmin; margin-right: 3vmin; }
    }
}
@media screen and (min-width: 761px) and (max-width: 900px) {
    .article {
        width: 90vmin;
        justify-content: center;
        a {
            margin-left: 20px;
            margin-bottom: 20px;
        }
    }
}